.how {
  background-color: var(--light-gray);

  &__container {
    padding-top: 75px;
    padding-bottom: 112px;

    @include small-tablet {
      padding-top: 50px;
      padding-bottom: 75px;
    }
  }

  &__heading {
    @include h2;
    @include ff-MontserratAlter;
    font-weight: 900;
    text-align: center;
    color: var(--gray);
    margin-bottom: 75px;

    @include small-tablet {
      margin-bottom: 40px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 44px 60px;
  }
}

.step {
  position: relative;
  flex-basis: 360px;
  display: flex;
  gap: 20px;
  padding: 36px 26px 36px 20px;
  background-color: var(--white);
  box-shadow: 0 0 54px rgba(0, 0, 0, 0.09);
  border-radius: 40px;
  transition: background-color .3s ease-in-out;

  &::after {
    content: url("../img/arrow-down.png");
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @include extra-desktop {
      display: none;
    }
  }

  &:hover {
    background-color: var(--orange);
  }

  &:hover &__title {
    color: var(--white);
  }

  &:hover &__description {
    color: var(--white);
  }

  &:hover &__number {
    border-color: var(--white);
    text-shadow: none;
  }

  &:nth-child(1) {
    &::after {
      bottom: -39%;
      left: 71%;
      z-index: 2;
    }
  }

  &:nth-child(2) {
    order: 4;
    &::after {
      content: url("../img/arrow-up.png");
      bottom: auto;
      top: -50%;
      left: 70%;
      z-index: 2;
    }

    @include extra-desktop {
      order: 2;
    }
  }
  &:nth-child(3) {
    order: 2;

    &::after {
      bottom: -39%;
      left: 71%;
      z-index: 2;
    }

    @include extra-desktop {
      order: 3;
    }
  }
  &:nth-child(4) {
    order: 5;
    &::after {
      content: url("../img/arrow-up.png");
      bottom: auto;
      top: -50%;
      left: 70%;
      z-index: 2;
    }

    @include extra-desktop {
      order: 4;
    }
  }
  &:nth-child(5) {
    order: 3;

    &::after {
      display: none;
    }

    @include extra-desktop {
      order: 5;
    }
  }

  &__number {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--gray);
    border-radius: 100%;
    @include ff-MontserratAlter;
    font-size: 46px;
    line-height: 46px;
    font-weight: 900;
    color: var(--white);
    transition: border-color .3s ease-in-out, color .3s ease-in-out, text-shadow .3s ease-in-out;
    text-shadow: -0   -2px 0 var(--gray),
                  0   -2px 0 var(--gray),
                  -0 2px 0 var(--gray),
                  0 2px 0 var(--gray),
                  -2px -0 0 var(--gray),
                  2px -0 0 var(--gray),
                  -2px 0 0 var(--gray),
                  2px 0 0 var(--gray),
                  -1px -2px 0 var(--gray),
                  1px -2px 0 var(--gray),
                  -1px 2px 0 var(--gray),
                  1px 2px 0 var(--gray),
                  -2px -1px 0 var(--gray),
                  2px -1px 0 var(--gray),
                  -2px 1px 0 var(--gray),
                  2px 1px 0 var(--gray),
                  -2px -2px 0 var(--gray),
                  2px -2px 0 var(--gray),
                  -2px 2px 0 var(--gray),
                  2px 2px 0 var(--gray),
                  -2px -2px 0 var(--gray),
                  2px -2px 0 var(--gray),
                  -2px 2px 0 var(--gray),
                  2px 2px 0 var(--gray);
  }

  &__title {
    @include ff-MontserratAlter;
    font-size: 21px;
    line-height: 25px;
    font-weight: 900;
    color: var(--gray);
    transition: color .3s ease-in-out;
  }

  &__description {
    @include fz-16;
    font-weight: 400;
    color: var(--gray);
    transition: color .3s ease-in-out;
  }
}
