.banner {
  position: relative;
  padding: 254px 0 108px;
  background-image: url("../img/splush-top.svg"), url("../img/splush-bottom.svg");
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: var(--light-green);

  @include big-desktop {
    background-image: none;
  }

  @include tablet {
    padding-top: 150px;
    padding-bottom: 75px;
  }

  &__container {
    position: relative;
  }

  &__image {
    position: absolute;
    right: -100px;
    bottom: -40px;
    z-index: 1;

    @include tablet {
      width: 40%;
      right: 0;
      bottom: -75px;
    }

    @include mobile {
      display: none;
    }
  }

  &__heading {
    position: relative;
    max-width: 750px;
    margin-bottom: 44px;
    @include ff-MontserratAlter;
    font-size: 60px;
    line-height: 73px;
    font-weight: 900;
    color: var(--gray);
    z-index: 2;

    @include extra-desktop {
      font-size: 50px;
      line-height: 63px;
    }

    @include small-tablet {
      max-width: 600px;
      font-size: 40px;
      line-height: 53px;
    }
  }

  &__btn {
    position: relative;
    display: inline-block;
    @include btn-orange;
    margin-left: 32px;
    z-index: 2;

    @include mobile {
      display: block;
      max-width: 400px;
      margin: 0 auto;
      text-align: center;
    }
  }
}

.banner ul {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-bottom: 45px;
}

.banner ul li {
  padding-left: 32px;
  @include fz-20;
  font-weight: 500;
  color: var(--gray);
  background: url("../img/check.svg") left top no-repeat transparent;
}
