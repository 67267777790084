:root {
  --font-family: "Muller", "Montserrat", "MontserratAlter", sans-serif;
  --content-width: 1200px;
  --container-offset: 15px;
  --container-offset-1440: 60px;
  --container-offset-1024: 30px;
  --container-offset-320: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --white: #fff;
  --orange: #E95005;
  --orange-hover: #cf4805;
  --green: #6e9b29;
  --green-86: #86C232;
  --green-hover: #577a20;
  --dark-gray: #5E6763;
  --gray-80: #80937F;
  --gray: #6C7772;
  --light-gray: #EAEDEB;
  --light-green: #F2FFEC;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */
@font-face {
  font-family: "Muller";
  src: url("../fonts/../fonts/MullerBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/../fonts/MullerMedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Muller";
  src: url("../fonts/../fonts/MullerRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/montserrat-bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/montserrat-medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/montserrat-semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "MontserratAlter";
  src: url("../fonts/../fonts/montserratalternates-black.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "MontserratAlter";
  src: url("../fonts/../fonts/montserratalternates-bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "MontserratAlter";
  src: url("../fonts/../fonts/montserratalternates-extrabold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "MontserratAlter";
  src: url("../fonts/../fonts/montserratalternates-regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: var(--font-family);
}

.page {
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  position: relative;
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 1230px) {
  .container {
    padding: 0 var(--container-offset-1440);
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: 100%;
    padding: 0 var(--container-offset-1024);
  }
}
@media (max-width: 576px) {
  .container {
    padding: 0 var(--container-offset-320);
  }
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.color-green {
  color: var(--green);
}

.heading-reset {
  margin: 0;
  padding: 0;
}

.input-reset {
  background-color: transparent;
  border: none;
  padding: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  display: none;
}

.overflow {
  overflow: hidden;
}

.header {
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 1230px;
  width: 100%;
  transform: translateX(-50%);
  z-index: 3;
}
@media (max-width: 1024px) {
  .header {
    background-color: var(--white);
    box-shadow: 0 20px 20px 20px rgba(0, 0, 0, 0.1);
  }
}
.header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 0 35px 0;
}
@media (max-width: 1024px) {
  .header__top {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.header__links {
  display: flex;
  align-items: center;
  gap: 45px;
}
@media (max-width: 1024px) {
  .header__links {
    display: none;
  }
}
.header__personal {
  display: flex;
  align-items: center;
  gap: 27px;
}
@media (max-width: 1024px) {
  .header__personal {
    display: none;
  }
}
.header__email {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--gray);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .header__email {
    font-size: 14px;
    line-height: 14px;
  }
}
.header__email:hover {
  color: var(--green);
}
.header__tel {
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  color: var(--gray);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .header__tel {
    font-size: 16px;
    line-height: 16px;
  }
}
.header__tel:hover {
  color: var(--green);
}
.header__callback {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: var(--green);
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .header__callback {
    font-size: 14px;
    line-height: 14px;
  }
}
@media (max-width: 1024px) {
  .header__callback {
    display: none;
  }
}
.header__callback:hover {
  border-color: var(--green);
  color: var(--green);
}
.header__enter {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--gray);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .header__enter {
    font-size: 14px;
    line-height: 14px;
  }
}
.header__enter:hover {
  color: var(--green);
}
.header__enter:hover .header__icon {
  fill: var(--green);
}
.header__icon {
  margin-right: 7px;
  width: 20px;
  height: 20px;
  fill: var(--gray);
  transition: fill 0.3s ease-in-out;
}
.header__btn {
  padding: 14px 24px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  border: 2px solid transparent;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, border-colo 0.3s ease-in-out;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .header__btn {
    font-size: 16px;
    line-height: 16px;
  }
}
.header__btn:hover {
  box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
  color: var(--white);
  background-color: var(--green-hover);
}
.header__btn:focus {
  outline: none;
  border-color: var(--green-hover);
}

.nav {
  position: relative;
  background-color: var(--white);
  padding: 19px 47px;
  box-shadow: 0 12px 24px rgba(134, 194, 50, 0.2);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .nav {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    height: 100vh;
    border-radius: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    transform: translateX(101%);
  }
}
.nav__list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .nav__list {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
}
.nav__link {
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  color: var(--gray);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .nav__link {
    font-size: 16px;
    line-height: 16px;
  }
}
.nav__link:hover {
  color: var(--green);
}

.burger {
  display: none;
  position: relative;
  width: 30px;
  height: 20px;
}
@media (max-width: 1024px) {
  .burger {
    display: block;
  }
}
.burger::after, .burger::before {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background-color: var(--gray);
}
.burger::after {
  top: auto;
  bottom: 0;
}
.burger__line {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3px;
  background-color: var(--gray);
}

.burger-open {
  transform: none;
}

.burger-cross {
  width: 20px;
  height: 20px;
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 3;
  fill: var(--gray);
  stroke: var(--gray);
}
@media (max-width: 1024px) {
  .burger-cross {
    display: block;
  }
}

.burger-links {
  display: none;
}
@media (max-width: 1024px) {
  .burger-links {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
}

.burger-personal {
  display: none;
}
@media (max-width: 1024px) {
  .burger-personal {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}

.banner {
  position: relative;
  padding: 254px 0 108px;
  background-image: url("../img/splush-top.svg"), url("../img/splush-bottom.svg");
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: var(--light-green);
}
@media (max-width: 1600px) {
  .banner {
    background-image: none;
  }
}
@media (max-width: 1024px) {
  .banner {
    padding-top: 150px;
    padding-bottom: 75px;
  }
}
.banner__container {
  position: relative;
}
.banner__image {
  position: absolute;
  right: -100px;
  bottom: -40px;
  z-index: 1;
}
@media (max-width: 1024px) {
  .banner__image {
    width: 40%;
    right: 0;
    bottom: -75px;
  }
}
@media (max-width: 576px) {
  .banner__image {
    display: none;
  }
}
.banner__heading {
  position: relative;
  max-width: 750px;
  margin-bottom: 44px;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 60px;
  line-height: 73px;
  font-weight: 900;
  color: var(--gray);
  z-index: 2;
}
@media (max-width: 1230px) {
  .banner__heading {
    font-size: 50px;
    line-height: 63px;
  }
}
@media (max-width: 880px) {
  .banner__heading {
    max-width: 600px;
    font-size: 40px;
    line-height: 53px;
  }
}
.banner__btn {
  position: relative;
  display: inline-block;
  padding: 18px 56px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: var(--white);
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: var(--orange);
  transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  margin-left: 32px;
  z-index: 2;
}
@media (max-width: 1024px) {
  .banner__btn {
    font-size: 18px;
    line-height: 18px;
  }
}
.banner__btn:hover {
  box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
  color: var(--white);
  background-color: var(--orange-hover);
}
@media (max-width: 576px) {
  .banner__btn {
    display: block;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
}

.banner ul {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-bottom: 45px;
}

.banner ul li {
  padding-left: 32px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: var(--gray);
  background: url("../img/check.svg") left top no-repeat transparent;
}
@media (max-width: 1024px) {
  .banner ul li {
    font-size: 18px;
    line-height: 18px;
  }
}

.advantages {
  background-color: var(--light-gray);
}
.advantages__container {
  padding-top: 75px;
  padding-bottom: 90px;
}
@media (max-width: 880px) {
  .advantages__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.advantages__heading {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  text-align: center;
  margin-bottom: 64px;
}
@media (max-width: 1230px) {
  .advantages__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .advantages__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .advantages__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 880px) {
  .advantages__heading {
    margin-bottom: 50px;
  }
}

.advantages-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 45px;
}
@media (max-width: 1230px) {
  .advantages-list {
    gap: 30px 30px;
  }
}
@media (max-width: 880px) {
  .advantages-list {
    flex-direction: column;
  }
}
.advantages-list__item {
  flex-basis: 48%;
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 1024px) {
  .advantages-list__item {
    gap: 30px;
  }
}
.advantages-list__heading {
  margin-bottom: 5px;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 26px;
  line-height: 32px;
  font-weight: 900;
  color: var(--gray);
}
@media (max-width: 1024px) {
  .advantages-list__heading {
    font-size: 20px;
    line-height: 26px;
  }
}
.advantages-list__text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--gray);
}
@media (max-width: 1024px) {
  .advantages-list__text {
    font-size: 14px;
    line-height: 14px;
  }
}

.rent__container {
  padding-top: 75px;
  padding-bottom: 100px;
}
@media (max-width: 1024px) {
  .rent__container {
    width: 100%;
  }
}
@media (max-width: 880px) {
  .rent__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.rent__heading {
  margin-bottom: 70px;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  text-align: center;
}
@media (max-width: 1230px) {
  .rent__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .rent__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .rent__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 1024px) {
  .rent__heading {
    margin-bottom: 50px;
  }
}
.rent__content {
  margin-bottom: 30px;
}
.rent .banner__btn {
  display: block;
  margin: 0 auto;
  max-width: fit-content;
}

.rent {
  display: flex;
}
@media (max-width: 1024px) {
  .rent {
    flex-direction: column;
  }
}

.parameters,
.composition {
  flex-basis: 50%;
  padding: 70px 36px;
}

.parameters {
  background: linear-gradient(144.34deg, #C9D824 14.99%, #86C232 30.49%, #00A99D 84.21%);
  border-radius: 40px 0 0 40px;
}
@media (max-width: 1024px) {
  .parameters {
    border-radius: 40px 40px 0 0;
  }
}
.parameters__heading {
  margin-bottom: 32px;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 26px;
  line-height: 32px;
  font-weight: 900;
  color: var(--white);
}
.parameters__users {
  margin-bottom: 30px;
}
.parameters__users, .parameters__price {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 1024px) {
  .parameters__users, .parameters__price {
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .parameters__users, .parameters__price {
    flex-direction: column;
    align-items: flex-start;
  }
}
.parameters__description {
  position: relative;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: var(--white);
  flex-basis: 49%;
}
.parameters__control {
  display: flex;
  align-items: center;
}
.parameters__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  color: var(--white);
  border-radius: 100%;
  border-color: transparent;
  background-color: var(--gray);
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  cursor: pointer;
}
.parameters__button:hover {
  background-color: var(--dark-gray);
}
.parameters__count {
  max-width: 80px;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
  color: var(--white);
  text-align: center;
}
.parameters__value {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 900;
  color: var(--white);
}

.composition {
  background: var(--light-gray);
  border-radius: 0 40px 40px 0;
}
@media (max-width: 1024px) {
  .composition {
    border-radius: 0 0 40px 40px;
  }
}
.composition__heading {
  margin-bottom: 32px;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 26px;
  line-height: 32px;
  font-weight: 900;
  color: var(--gray);
}

.tooltip {
  position: absolute;
  bottom: 2px;
  right: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  border: 1px solid var(--white);
  border-radius: 100%;
  cursor: pointer;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.tooltip:hover {
  border-color: var(--gray);
  color: var(--gray);
}
@media (max-width: 576px) {
  .tooltip {
    position: relative;
    display: inline-flex;
    right: auto;
    left: auto;
    margin-left: 10px;
  }
}

.tooltip[aria-expanded=true] {
  border-color: var(--gray);
  color: var(--gray);
}

.tippy-content {
  padding: 19px 15px;
  background-color: var(--gray);
  border: 2px dotted var(--white);
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: var(--white);
}

.composition ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.composition ul li {
  padding-left: 28px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: var(--gray);
  background-image: url("../img/check-gray.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-color: transparent;
}
@media (max-width: 1024px) {
  .composition ul li {
    font-size: 16px;
    line-height: 16px;
  }
}

.request {
  background: url("../img/splush-top-white.svg") left top no-repeat var(--orange);
}
.request__container {
  padding-top: 85px;
  padding-bottom: 85px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .request__container {
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: column;
    gap: 50px;
  }
}
.request__left, .request__right {
  flex-basis: 50%;
}
.request__heading {
  max-width: 600px;
  margin-bottom: 25px;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  color: var(--white);
}
@media (max-width: 1230px) {
  .request__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .request__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .request__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 1230px) {
  .request__heading {
    max-width: 400px;
  }
}
.request__title {
  display: block;
  margin-bottom: 1em;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: var(--white);
}
.request__description {
  max-width: 400px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: var(--white);
}

.request-form {
  position: relative;
  display: flex;
  flex-direction: column;
}
.request-form__label {
  width: 100%;
}
.request-form__label:not(:last-child) {
  margin-bottom: 51px;
}
.request-form__input {
  padding: 4px 13px;
  width: 100%;
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--white);
  border-bottom: 2px dashed var(--white);
}
.request-form__input::placeholder {
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: var(--white);
  text-transform: uppercase;
}
.request-form__bottom {
  display: flex;
  align-items: center;
  gap: 45px;
}
@media (max-width: 576px) {
  .request-form__bottom {
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }
}
.request-form__button {
  padding: 14px 24px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  border: 2px solid transparent;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, border-colo 0.3s ease-in-out;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .request-form__button {
    font-size: 16px;
    line-height: 16px;
  }
}
.request-form__button:hover {
  box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
  color: var(--white);
  background-color: var(--green-hover);
}
.request-form__button:focus {
  outline: none;
  border-color: var(--green-hover);
}
.request-form__image {
  position: absolute;
  top: -20px;
  right: 0;
  z-index: 2;
  background-color: var(--orange);
}
@media (max-width: 576px) {
  .request-form__image {
    display: none;
  }
}
.request-form__policy, .request-form .policy__link {
  max-width: 210px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: var(--white);
}
.request-form .policy__link {
  border-bottom: 1px solid var(--white);
  transition: border-color 0.3s ease-in-out;
}
.request-form .policy__link:hover {
  border-color: transparent;
}

#name {
  max-width: 399px;
}
@media (max-width: 1230px) {
  #name {
    max-width: 305px;
  }
}
@media (max-width: 1024px) {
  #name {
    max-width: 100%;
  }
}

.services__container {
  padding-top: 77px;
  padding-bottom: 35px;
}
@media (max-width: 880px) {
  .services__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.services__heading {
  margin-bottom: 69px;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  text-align: center;
}
@media (max-width: 1230px) {
  .services__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .services__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .services__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 576px) {
  .services__heading {
    margin-bottom: 40px;
  }
}
.services__content {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-rows: 273px;
  border-radius: 40px;
  overflow: hidden;
  margin-bottom: 80px;
}
@media (max-width: 1230px) {
  .services__content {
    grid-template-columns: auto auto;
    margin-bottom: 40px;
  }
}
@media (max-width: 880px) {
  .services__content {
    grid-template-columns: auto;
  }
}
.services__link {
  padding: 14px 24px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  border: 2px solid transparent;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, border-colo 0.3s ease-in-out;
  cursor: pointer;
  background-color: var(--gray);
  display: block;
  max-width: fit-content;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .services__link {
    font-size: 16px;
    line-height: 16px;
  }
}
.services__link:hover {
  box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
  color: var(--white);
  background-color: var(--green-hover);
}
.services__link:focus {
  outline: none;
  border-color: var(--green-hover);
}
.services__link:hover {
  background-color: var(--dark-gray);
}

.service {
  display: flex;
  gap: 20px;
  background-color: var(--green-86);
  padding-top: 72px;
  padding-left: 20px;
  padding-right: 10px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}
.service:hover {
  box-shadow: 10px 14px 144px rgba(255, 255, 255, 0.75);
  z-index: 1;
}
.service__icon {
  width: 60px;
  height: 60px;
  fill: var(--white);
  flex-shrink: 0;
}
.service__heading {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 26px;
  line-height: 32px;
  font-weight: 900;
  color: var(--white);
}
@media (max-width: 880px) {
  .service__heading {
    margin-bottom: 15px;
  }
}
.service__description {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--white);
}
@media (max-width: 1024px) {
  .service__description {
    font-size: 14px;
    line-height: 14px;
  }
}

.useful__container {
  padding-top: 35px;
  padding-bottom: 100px;
}
@media (max-width: 880px) {
  .useful__container {
    padding-bottom: 50px;
  }
}
.useful__head {
  position: relative;
}
@media (max-width: 576px) {
  .useful__head {
    display: flex;
  }
}
.useful__heading {
  margin-bottom: 63px;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  text-align: center;
}
@media (max-width: 1230px) {
  .useful__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .useful__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .useful__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 880px) {
  .useful__heading {
    margin-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .useful__heading {
    text-align: left;
  }
}
.useful__buttons {
  position: absolute;
  right: 0;
  top: 60%;
  transform: translateY(-50%);
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .useful__buttons {
    width: 80px;
  }
}

.swiper-button-next, .swiper-button-prev {
  color: var(--gray-80) !important;
}

.useful-next, .useful-prev {
  transition: color 0.3s ease-in-out;
}
.useful-next:hover, .useful-prev:hover {
  color: var(--gray) !important;
}

.useful-card {
  background-color: var(--white);
  box-shadow: inset 0 0 54px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  cursor: pointer;
}
.useful-card__top {
  position: relative;
}
.useful-card__heading {
  padding: 19px 0 24px;
  border-top: 1px solid #EFF1E4;
  text-align: center;
}
.useful-card__heading a {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: var(--gray-80);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .useful-card__heading a {
    font-size: 16px;
    line-height: 16px;
  }
}
.useful-card__heading a:hover {
  color: var(--gray);
}
.useful-card__content {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 43px 24px 34px 51px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--gray-80);
  background-color: var(--light-green);
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}
@media (max-width: 1024px) {
  .useful-card__content {
    font-size: 14px;
    line-height: 14px;
  }
}
@media (max-width: 1024px) {
  .useful-card__content {
    padding: 43px 25px;
  }
}
.useful-card__description {
  margin-bottom: 42px;
}
.useful-card__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.useful-card__price {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 28px;
  line-height: 42px;
  font-weight: 400;
  color: var(--gray);
}
.useful-card__link {
  padding: 14px 24px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  border: 2px solid transparent;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, border-colo 0.3s ease-in-out;
  cursor: pointer;
  display: block;
}
@media (max-width: 1024px) {
  .useful-card__link {
    font-size: 16px;
    line-height: 16px;
  }
}
.useful-card__link:hover {
  box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
  color: var(--white);
  background-color: var(--green-hover);
}
.useful-card__link:focus {
  outline: none;
  border-color: var(--green-hover);
}

.useful-card .image {
  padding: 20px 0 10px;
  display: block;
  margin: 0 auto;
  transition: opacity 0.3s ease-in-out;
}
.useful-card .image:hover {
  opacity: 0;
  z-index: -1;
}

.useful-card:hover .useful-card__content {
  opacity: 1;
  z-index: 2;
}

.fw-800 {
  font-weight: 800;
}

@media (max-width: 880px) {
  .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after,
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    font-size: 30px !important;
  }
}

.fancybox__slide .callback {
  background-color: var(--green);
  max-width: fit-content;
  width: 100%;
}
.fancybox__slide .callback__content {
  display: flex;
  gap: 30px;
}
.fancybox__slide .callback__left {
  display: flex;
  flex-direction: column;
  max-width: 40%;
  width: 100%;
}
.fancybox__slide .callback__description {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: var(--white);
  max-width: 350px;
}
@media (max-width: 1024px) {
  .fancybox__slide .callback__description {
    font-size: 16px;
    line-height: 16px;
  }
}
.fancybox__slide .callback__heading {
  max-width: 300px;
  font-size: 37px;
  line-height: 42px;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-weight: 900;
  color: var(--white);
  margin-bottom: 35px;
}

.callback-form {
  display: flex;
  flex-direction: column;
}
.callback-form__fieldset {
  border: none;
  display: flex;
  margin: 0;
  justify-content: space-between;
  padding: 25px 35px 50px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
}
.callback-form__label {
  width: 45%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 12px;
  gap: 5px;
  font-weight: 400;
  color: var(--gray);
}
.callback-form__input {
  border-bottom: 1px solid var(--gray);
  padding: 5px 0;
  font-size: 18px;
  line-height: 18px;
  color: var(--gray);
}
@media (max-width: 1024px) {
  .callback-form__input {
    font-size: 16px;
    line-height: 16px;
  }
}
.callback-form__input::placeholder {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: var(--light-gray);
}
@media (max-width: 1024px) {
  .callback-form__input::placeholder {
    font-size: 16px;
    line-height: 16px;
  }
}
.callback-form__bottom {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}
.callback-form__policy {
  max-width: 250px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: var(--white);
  text-align: right;
}
.callback-form__policy-link {
  color: var(--white);
  border-bottom: 1px solid var(--white);
  transition: border-color 0.3s ease-in-out;
}
.callback-form__policy-link:hover {
  border-color: transparent;
  color: var(--white);
}
.callback-form__button {
  padding: 18px 56px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: var(--white);
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: var(--orange);
  transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .callback-form__button {
    font-size: 18px;
    line-height: 18px;
  }
}
.callback-form__button:hover {
  box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
  color: var(--white);
  background-color: var(--orange-hover);
}

.footer {
  background-color: var(--dark-gray);
}
.footer__container {
  padding-top: 80px;
  padding-bottom: 70px;
  display: flex;
  gap: 60px;
}
@media (max-width: 1230px) {
  .footer__container {
    padding-top: 50px;
  }
}
@media (max-width: 880px) {
  .footer__container {
    flex-wrap: wrap;
    gap: 30px 0;
  }
}
@media (max-width: 576px) {
  .footer__container {
    gap: 30px 5px;
  }
}
.footer__data {
  display: flex;
  flex-direction: column;
}
@media (max-width: 880px) {
  .footer__data {
    flex-basis: 50%;
  }
}
@media (max-width: 576px) {
  .footer__data {
    flex-basis: 100%;
  }
}
.footer__logo {
  margin-bottom: 13px;
}
.footer__description {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--white);
  margin-bottom: 26px;
}
@media (max-width: 1024px) {
  .footer__description {
    font-size: 14px;
    line-height: 14px;
  }
}
.footer__links {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 880px) {
  .footer__links {
    flex-basis: 50%;
    padding-top: 0;
  }
}
.footer__copyright {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #dcdcdc;
}
.footer__tel {
  white-space: nowrap;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: var(--green);
  transition: color 0.3s ease-in-out;
}
.footer__tel:hover {
  color: var(--green-hover);
}
.footer__callback {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--green);
  border-bottom: 1px solid var(--green);
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .footer__callback {
    font-size: 14px;
    line-height: 14px;
  }
}
.footer__callback:hover {
  border-color: transparent;
  color: var(--green-hover);
}
.footer__email {
  font-family: "Montserrat", "MontserratAlter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: var(--white);
}
.footer__offer, .footer__policy {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  transition: border-color 0.3s ease-in-out;
}
.footer__offer:hover, .footer__policy:hover {
  border-color: transparent;
}
.footer__policy {
  margin-bottom: 14px;
}
@media (max-width: 880px) {
  .footer__policy {
    display: block;
  }
}
.footer__malevich {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: #dcdcdc;
}
.footer__malevich-link {
  color: #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  transition: border-color 0.3s ease-in-out;
}
.footer__malevich-link:hover {
  color: #dcdcdc;
  border-color: transparent;
}
@media (max-width: 576px) {
  .footer__info {
    flex-basis: 50%;
  }
}

.footer-nav {
  padding-top: 30px;
}
@media (max-width: 880px) {
  .footer-nav {
    flex-basis: 50%;
  }
}
@media (max-width: 576px) {
  .footer-nav {
    padding-top: 0;
    flex-basis: 100%;
  }
}
.footer-nav__list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
@media (max-width: 880px) {
  .footer-nav__list {
    flex-direction: column;
  }
}
.footer-nav__item:nth-child(odd) {
  flex-basis: 41%;
}
.footer-nav__link {
  font-family: "Montserrat", "MontserratAlter", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: var(--white);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1230px) {
  .footer-nav__link {
    font-size: 16px;
    line-height: 20px;
  }
}
.footer-nav__link:hover {
  color: var(--green);
}

.footer-social {
  display: flex;
  gap: 13px;
  margin-bottom: 14px;
}
.footer-social__link {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-image: linear-gradient(144.34deg, #C9D824 14.99%, #86C232 30.49%, #00A99D 84.21%);
  background-size: 200% auto;
  transition: background-position 0.3s ease-in-out;
}
.footer-social__link:hover {
  background-position: right bottom;
}
.footer-social__icon {
  width: 18px;
  height: 18px;
  fill: var(--white);
}

.what-is__container {
  padding-top: 75px;
  padding-bottom: 90px;
}
@media (max-width: 880px) {
  .what-is__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.what-is__container h2 {
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-weight: 900;
  color: var(--gray);
  padding: 0;
  margin: 0 0 72px;
  text-align: center;
}
@media (max-width: 1230px) {
  .what-is__container h2 {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .what-is__container h2 {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .what-is__container h2 {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 880px) {
  .what-is__container h2 {
    margin-bottom: 40px;
  }
}

.what-is__description p {
  margin: 0 0 1em;
  padding: 0;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  color: var(--gray);
  text-align: justify;
}
@media (max-width: 880px) {
  .what-is__description p {
    font-size: 16px;
    line-height: 21px;
  }
}

.how {
  background-color: var(--light-gray);
}
.how__container {
  padding-top: 75px;
  padding-bottom: 112px;
}
@media (max-width: 880px) {
  .how__container {
    padding-top: 50px;
    padding-bottom: 75px;
  }
}
.how__heading {
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-weight: 900;
  text-align: center;
  color: var(--gray);
  margin-bottom: 75px;
}
@media (max-width: 1230px) {
  .how__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .how__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .how__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 880px) {
  .how__heading {
    margin-bottom: 40px;
  }
}
.how__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 44px 60px;
}

.step {
  position: relative;
  flex-basis: 360px;
  display: flex;
  gap: 20px;
  padding: 36px 26px 36px 20px;
  background-color: var(--white);
  box-shadow: 0 0 54px rgba(0, 0, 0, 0.09);
  border-radius: 40px;
  transition: background-color 0.3s ease-in-out;
}
.step::after {
  content: url("../img/arrow-down.png");
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1230px) {
  .step::after {
    display: none;
  }
}
.step:hover {
  background-color: var(--orange);
}
.step:hover .step__title {
  color: var(--white);
}
.step:hover .step__description {
  color: var(--white);
}
.step:hover .step__number {
  border-color: var(--white);
  text-shadow: none;
}
.step:nth-child(1)::after {
  bottom: -39%;
  left: 71%;
  z-index: 2;
}
.step:nth-child(2) {
  order: 4;
}
.step:nth-child(2)::after {
  content: url("../img/arrow-up.png");
  bottom: auto;
  top: -50%;
  left: 70%;
  z-index: 2;
}
@media (max-width: 1230px) {
  .step:nth-child(2) {
    order: 2;
  }
}
.step:nth-child(3) {
  order: 2;
}
.step:nth-child(3)::after {
  bottom: -39%;
  left: 71%;
  z-index: 2;
}
@media (max-width: 1230px) {
  .step:nth-child(3) {
    order: 3;
  }
}
.step:nth-child(4) {
  order: 5;
}
.step:nth-child(4)::after {
  content: url("../img/arrow-up.png");
  bottom: auto;
  top: -50%;
  left: 70%;
  z-index: 2;
}
@media (max-width: 1230px) {
  .step:nth-child(4) {
    order: 4;
  }
}
.step:nth-child(5) {
  order: 3;
}
.step:nth-child(5)::after {
  display: none;
}
@media (max-width: 1230px) {
  .step:nth-child(5) {
    order: 5;
  }
}
.step__number {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--gray);
  border-radius: 100%;
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 46px;
  line-height: 46px;
  font-weight: 900;
  color: var(--white);
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
  text-shadow: 0 -2px 0 var(--gray), 0 -2px 0 var(--gray), 0 2px 0 var(--gray), 0 2px 0 var(--gray), -2px 0 0 var(--gray), 2px 0 0 var(--gray), -2px 0 0 var(--gray), 2px 0 0 var(--gray), -1px -2px 0 var(--gray), 1px -2px 0 var(--gray), -1px 2px 0 var(--gray), 1px 2px 0 var(--gray), -2px -1px 0 var(--gray), 2px -1px 0 var(--gray), -2px 1px 0 var(--gray), 2px 1px 0 var(--gray), -2px -2px 0 var(--gray), 2px -2px 0 var(--gray), -2px 2px 0 var(--gray), 2px 2px 0 var(--gray), -2px -2px 0 var(--gray), 2px -2px 0 var(--gray), -2px 2px 0 var(--gray), 2px 2px 0 var(--gray);
}
.step__title {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 21px;
  line-height: 25px;
  font-weight: 900;
  color: var(--gray);
  transition: color 0.3s ease-in-out;
}
.step__description {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--gray);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .step__description {
    font-size: 14px;
    line-height: 14px;
  }
}

.programs {
  background-color: var(--dark-gray);
}
.programs__container {
  padding-top: 65px;
  padding-bottom: 130px;
}
@media (max-width: 880px) {
  .programs__container {
    padding-top: 50px;
    padding-bottom: 75px;
  }
}
.programs__heading {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-weight: 900;
  color: var(--white);
  margin-bottom: 75px;
  text-align: center;
}
@media (max-width: 1230px) {
  .programs__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .programs__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .programs__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 880px) {
  .programs__heading {
    margin-bottom: 40px;
  }
}
.programs__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 270px;
  gap: 5px;
}
@media (max-width: 1024px) {
  .programs__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .programs__content {
    grid-template-columns: repeat(1, 1fr);
  }
}

.program {
  padding: 62px 20px;
  border: 2px dashed var(--white);
  border-radius: 40px;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.program:hover {
  box-shadow: 10px 14px 144px rgba(255, 255, 255, 0.75);
  background-color: var(--green-86);
}
.program:hover .program__heading {
  color: var(--dark-gray);
}
.program__heading {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 900;
  color: var(--green);
  transition: color 0.3s ease-in-out;
}
.program__description {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--white);
}
@media (max-width: 1024px) {
  .program__description {
    font-size: 14px;
    line-height: 14px;
  }
}

.comfortable__container {
  padding-top: 90px;
  padding-bottom: 90px;
}
@media (max-width: 880px) {
  .comfortable__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.comfortable__heading {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-weight: 900;
  color: var(--gray);
  text-align: center;
  margin-bottom: 63px;
}
@media (max-width: 1230px) {
  .comfortable__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .comfortable__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .comfortable__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
.comfortable__content {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.user {
  padding: 36px 18px;
  flex-basis: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 54px rgba(0, 0, 0, 0.09);
}
@media (max-width: 1024px) {
  .user {
    flex-basis: 47%;
  }
}
@media (max-width: 576px) {
  .user {
    flex-basis: 100%;
  }
}
.user img {
  margin-bottom: 29px;
}
.user h3 {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 26px;
  line-height: 32px;
  font-weight: 900;
  color: var(--gray);
  margin-bottom: 26px;
}
.user ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.user ul li {
  padding-left: 32px;
  background-image: url("../img/check.svg");
  background-repeat: no-repeat;
  background-position: left top;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: var(--gray);
}
@media (max-width: 1024px) {
  .user ul li {
    font-size: 14px;
    line-height: 14px;
  }
}

.about__container {
  padding-top: 75px;
  padding-bottom: 75px;
}
.about__heading {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-weight: 900;
  color: var(--gray);
  margin-bottom: 72px;
  text-align: center;
}
@media (max-width: 1230px) {
  .about__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .about__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .about__heading {
    font-size: 28px;
    line-height: 38px;
  }
}
.about__description {
  font-size: 18px;
  line-height: 18px;
  line-height: 23px;
  font-weight: 400;
  color: var(--gray);
  text-align: justify;
}
@media (max-width: 1024px) {
  .about__description {
    font-size: 16px;
    line-height: 16px;
  }
}
.about__description p {
  margin-bottom: 1em;
}
.about__description ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}
.about__description ul li {
  padding: 0;
}

.tabs {
  background-color: var(--light-gray);
}
.tabs__container {
  padding-top: 75px;
  padding-bottom: 90px;
}
.tabs__heading {
  font-family: "MontserratAlter", "Montserrat", sans-serif;
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);
  font-weight: 900;
  color: var(--gray);
  margin-bottom: 69px;
  text-align: center;
}
@media (max-width: 1230px) {
  .tabs__heading {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (max-width: 1024px) {
  .tabs__heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media (max-width: 576px) {
  .tabs__heading {
    font-size: 28px;
    line-height: 38px;
  }
}

.tabs-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
}
@media (max-width: 1024px) {
  .tabs-category {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
.tabs-category__button {
  padding: 14px 19px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--dark-gray);
  border-radius: 8px;
  box-shadow: 0 10px 15px rgba(224, 225, 224, 0.2);
  transition: background-color 0.3s ease-in-out;
}
@media (max-width: 1024px) {
  .tabs-category__button {
    font-size: 16px;
    line-height: 16px;
  }
}
.tabs-category__button:hover {
  background-color: var(--green-86);
  color: var(--white);
}

.btn-active {
  background-color: transparent;
  color: var(--gray);
}

.tabs-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 273px;
  border-radius: 40px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .tabs-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 880px) {
  .tabs-content {
    grid-template-columns: repeat(1, 1fr);
  }
}