.rent {
  &__container {
    padding-top: 75px;
    padding-bottom: 100px;

    @include tablet {
      width: 100%;
    }

    @include small-tablet {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &__heading {
    margin-bottom: 70px;
    @include h2;
    @include ff-MontserratAlter;
    text-align: center;

    @include tablet {
      margin-bottom: 50px;
    }
  }

  &__content {
    margin-bottom: 30px;
  }

  & .banner__btn {
    display: block;
    margin: 0 auto;
    max-width: fit-content;
  }
}

.rent {
  display: flex;

  @include tablet {
    flex-direction: column;
  }
}

.parameters,
.composition {
  flex-basis: 50%;
  padding: 70px 36px;
}

.parameters {
  background: linear-gradient(144.34deg, #C9D824 14.99%, #86C232 30.49%, #00A99D 84.21%);
  border-radius: 40px 0 0 40px;

  @include tablet {
    border-radius: 40px 40px 0 0;
  }

  &__heading {
    margin-bottom: 32px;
    @include ff-MontserratAlter;
    font-size: 26px;
    line-height: 32px;
    font-weight: 900;
    color: var(--white);
  }

  &__users {
    margin-bottom: 30px;
  }

  &__users, &__price {
    display: flex;
    align-items: center;
    gap: 30px;

    @include tablet {
      justify-content: space-between;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__description {
    position: relative;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: var(--white);
    flex-basis: 49%;
  }

  &__control {
    display: flex;
    align-items: center;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    @include ff-MontserratAlter;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: var(--white);
    border-radius: 100%;
    border-color: transparent;
    background-color: var(--gray);
    transition: background-color .3s ease-in-out, border-color .3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--dark-gray);
    }
  }

  &__count {
    max-width: 80px;
    @include ff-MontserratAlter;
    font-size: 26px;
    line-height: 30px;
    font-weight: 800;
    color: var(--white);
    text-align: center;
  }

  &__value {
    @include ff-MontserratAlter;
    font-size: 26px;
    line-height: 30px;
    font-weight: 900;
    color: var(--white);
  }
}

.composition {
  background: var(--light-gray);
  border-radius: 0 40px 40px 0;

  @include tablet {
    border-radius: 0 0 40px 40px;
  }


  &__heading {
    margin-bottom: 32px;
    @include ff-MontserratAlter;
    font-size: 26px;
    line-height: 32px;
    font-weight: 900;
    color: var(--gray);
  }
}

.tooltip {
  position: absolute;
  bottom: 2px;
  right: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include ff-MontserratAlter;
  border: 1px solid var(--white);
  border-radius: 100%;
  cursor: pointer;
  transition: color .3s ease-in-out, border-color .3s ease-in-out;

  &:hover {
    border-color: var(--gray);
    color: var(--gray)
  }

  @include mobile {
    position: relative;
    display: inline-flex;
    right: auto;
    left: auto;
    margin-left: 10px;
  }
}

.tooltip[aria-expanded="true"] {
  border-color: var(--gray);
  color: var(--gray)
}

.tippy-content {
  padding: 19px 15px;
  background-color: var(--gray);
  border: 2px dotted var(--white);
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: var(--white);
}

.composition ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.composition ul li {
  padding-left: 28px;
  @include fz-18;
  font-weight: 400;
  color: var(--gray);
  background-image: url("../img/check-gray.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-color: transparent;
}
