.about {
  &__container {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &__heading {
    @include ff-MontserratAlter;
    @include h2;
    font-weight: 900;
    color: var(--gray);
    margin-bottom: 72px;
    text-align: center;
  }

  &__description {
    @include fz-18;
    line-height: 23px;
    font-weight: 400;
    color: var(--gray);
    text-align: justify;

    & p {
      margin-bottom: 1em;
    }

    & ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    & ul li {
      padding: 0;

    }
  }
}
