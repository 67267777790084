.header {
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 1230px;
  width: 100%;
  transform: translateX(-50%);
  z-index: 3;

  @include tablet {
    background-color: var(--white);
    box-shadow: 0 20px 20px 20px rgb(0 0 0 / 10%);
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px 0 35px 0;

    @include tablet {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 45px;

    @include tablet {
      display: none;
    }
  }

  &__personal {
    display: flex;
    align-items: center;
    gap: 27px;

    @include tablet {
      display: none;
    }
  }

  &__email {
    @include fz-16;
    font-weight: 400;
    color: var(--gray);
    transition: color .3s ease-in-out;

    &:hover {
      color: var(--green);
    }
  }

  &__tel {
    @include fz-18;
    font-weight: 700;
    color: var(--gray);
    transition: color .3s ease-in-out;

    &:hover {
      color: var(--green);
    }
  }

  &__callback {
    @include fz-16;
    font-weight: 500;
    color: var(--green);
    border-bottom: 1px solid transparent;
    transition: border-color .3s ease-in-out;

    @include tablet {
      display: none;
    }

    &:hover {
      border-color: var(--green);
      color: var(--green);
    }
  }

  &__enter {
    display: flex;
    align-items: center;
    @include fz-16;
    font-weight: 400;
    color: var(--gray);
    transition: color .3s ease-in-out;

    &:hover {
      color: var(--green);
    }

    &:hover .header__icon {
      fill: var(--green);
    }
  }

  &__icon {
    margin-right: 7px;
    width: 20px;
    height: 20px;
    fill: var(--gray);
    transition: fill .3s ease-in-out;
  }

  &__btn {
    @include btn-green;
  }
}

.nav {
  position: relative;
  background-color: var(--white);
  padding: 19px 47px;
  box-shadow: 0 12px 24px rgba(134, 194, 50, 0.2);
  border-radius: 8px;
  transition: transform .3s ease-in-out;

  @include tablet {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    height: 100vh;
    border-radius: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    transform: translateX(101%);
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
      gap: 30px;
      align-items: flex-start;
    }
  }

  &__link {
    @include fz-18;
    font-weight: 700;
    color: var(--gray);
    transition: color .3s ease-in-out;

    &:hover {
      color: var(--green);
    }
  }
}

.burger {
  display: none;
  position: relative;
  width: 30px;
  height: 20px;

  @include tablet {
    display: block;
  }

  &::after, &::before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    background-color: var(--gray);
  }

  &::after {
    top: auto;
    bottom: 0;
  }

  &__line {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3px;
    background-color: var(--gray);
  }
}

.burger-open {
  transform: none;
}

.burger-cross {
  width: 20px;
  height: 20px;
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 3;
  fill: var(--gray);
  stroke: var(--gray);

  @include tablet {
    display: block;
  }
}

.burger-links {
  display: none;

  @include tablet {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
}

.burger-personal {
  display: none;

  @include tablet {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}
