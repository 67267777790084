.programs {
  background-color: var(--dark-gray);

  &__container {
    padding-top: 65px;
    padding-bottom: 130px;

    @include small-tablet {
      padding-top: 50px;
      padding-bottom: 75px;
    }
  }

  &__heading {
    @include ff-MontserratAlter;
    @include h2;
    font-weight: 900;
    color: var(--white);
    margin-bottom: 75px;
    text-align: center;

    @include small-tablet {
      margin-bottom: 40px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3 ,1fr);
    grid-auto-rows: 270px;
    gap: 5px;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.program {
  padding: 62px 20px;
  border: 2px dashed var(--white);
  border-radius: 40px;
  transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out;

  &:hover {
    box-shadow: 10px 14px 144px rgba(255, 255, 255, 0.75);
    background-color: var(--green-86);
  }

  &:hover &__heading {
    color: var(--dark-gray);
  }

  &__heading {
    @include ff-MontserratAlter;
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
    color: var(--green);
    transition: color .3s ease-in-out;
  }

  &__description {
    @include fz-16;
    font-weight: 400;
    color: var(--white);
  }
}
