.comfortable {
  &__container {
    padding-top: 90px;
    padding-bottom: 90px;

    @include small-tablet {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &__heading {
    @include ff-MontserratAlter;
    @include h2;
    font-weight: 900;
    color: var(--gray);
    text-align: center;
    margin-bottom: 63px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}

.user {
  padding: 36px 18px;
  flex-basis: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 54px rgba(0, 0, 0, 0.09);

  @include tablet {
    flex-basis: 47%;
  }

  @include mobile {
    flex-basis: 100%;
  }

  & img {
    margin-bottom: 29px;
  }

  & h3 {
    @include ff-MontserratAlter;
    font-size: 26px;
    line-height: 32px;
    font-weight: 900;
    color: var(--gray);
    margin-bottom: 26px;
  }

  & ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  & ul li {
    padding-left: 32px;
    background-image: url("../img/check.svg");
    background-repeat: no-repeat;
    background-position: left top;
    @include fz-16;
    font-weight: 500;
    color: var(--gray);
  }
}
