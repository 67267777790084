.services {
  &__container {
    padding-top: 77px;
    padding-bottom: 35px;

    @include small-tablet {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &__heading {
    margin-bottom: 69px;
    @include h2;
    @include ff-MontserratAlter;
    text-align: center;

    @include mobile {
      margin-bottom: 40px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-rows: 273px;
    border-radius: 40px;
    overflow: hidden;
    margin-bottom: 80px;

    @include extra-desktop {
      grid-template-columns: auto auto;
      margin-bottom: 40px;
    }

    @include small-tablet {
      grid-template-columns: auto;
    }
  }

  &__link {
    @include btn-green;
    background-color: var(--gray);
    display: block;
    max-width: fit-content;
    margin: 0 auto;

    &:hover {
      background-color: var(--dark-gray);
    }
  }
}

.service {
  display: flex;
  gap: 20px;
  background-color: var(--green-86);
  padding-top: 72px;
  padding-left: 20px;
  padding-right: 10px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  transition: box-shadow .3s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 10px 14px 144px rgba(255, 255, 255, 0.75);
    z-index: 1;
  }

  &__icon {
    width: 60px;
    height: 60px;
    fill: var(--white);
    flex-shrink: 0;
  }

  &__heading {
    @include ff-MontserratAlter;
    font-size: 26px;
    line-height: 32px;
    font-weight: 900;
    color: var(--white);

    @include small-tablet {
      margin-bottom: 15px;
    }
  }

  &__description {
    @include fz-16;
    font-weight: 400;
    color: var(--white);
  }
}
