@mixin ff-MontserratAlter {
  font-family: 'MontserratAlter', 'Montserrat', sans-serif;
}

@mixin ff-Monserrat {
  font-family: 'Montserrat', 'MontserratAlter', sans-serif;
}

@mixin fz-16 {
  font-size: 16px;
  line-height: 16px;

  @include tablet {
    font-size: 14px;
    line-height: 14px;
  }
}

@mixin fz-18 {
  font-size: 18px;
  line-height: 18px;

  @include tablet {
    font-size: 16px;
    line-height: 16px;
  }
}

@mixin fz-20 {
  font-size: 20px;
  line-height: 20px;

  @include tablet {
    font-size: 18px;
    line-height: 18px;
  }
}

@mixin h2 {
  font-size: 46px;
  line-height: 56px;
  font-weight: 900;
  color: var(--gray);

  @include extra-desktop {
    font-size: 38px;
    line-height: 48px;
  }

  @include tablet {
    font-size: 32px;
    line-height: 42px;
  }

  @include mobile {
    font-size: 28px;
    line-height: 38px;
  }
}
