@mixin btn-orange {
  padding: 18px 56px;
  @include fz-20;
  font-weight: 500;
  color: var(--white);
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: var(--orange);
  transition: box-shadow .3s ease-in-out, color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;

  &:hover {
    box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
    color: var(--white);
    background-color: var(--orange-hover);
  }
}
