.request {
  background: url("../img/splush-top-white.svg") left top no-repeat var(--orange);

  &__container {
    padding-top: 85px;
    padding-bottom: 85px;
    display: flex;
    justify-content: space-between;

    @include tablet {
      padding-top: 50px;
      padding-bottom: 50px;
      flex-direction: column;
      gap: 50px;
    }
  }

  &__left, &__right {
    flex-basis: 50%;
  }

  &__heading {
    max-width: 600px;
    margin-bottom: 25px;
    @include ff-MontserratAlter;
    @include h2;
    color: var(--white);

    @include extra-desktop {
      max-width: 400px;
    }
  }

  &__title {
    display: block;
    margin-bottom: 1em;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    color: var(--white);
  }

  &__description {
    max-width: 400px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: var(--white);
  }
}

.request-form {
  position: relative;
  display: flex;
  flex-direction: column;

  &__label {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 51px;
    }
  }

  &__input {
    padding: 4px 13px;
    width: 100%;
    font-size: 20px;
    line-height: 21px;
    font-weight: 400;
    color: var(--white);
    border-bottom: 2px dashed var(--white);

    &::placeholder {
      font-size: 20px;
      line-height: 21px;
      font-weight: 400;
      color: var(--white);
      text-transform: uppercase;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 45px;

    @include mobile {
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
    }
  }

  &__button {
    @include btn-green;
  }

  &__image {
    position: absolute;
    top: -20px;
    right: 0;
    z-index: 2;
    background-color: var(--orange);

    @include mobile {
      display: none;
    }
  }

  &__policy, .policy__link {
    max-width: 210px;
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: var(--white);
  }

  .policy__link {
    border-bottom: 1px solid var(--white);
    transition: border-color .3s ease-in-out;

    &:hover {
      border-color: transparent;
    }
  }
}

#name {
  max-width: 399px;

  @include extra-desktop {
    max-width: 305px;
  }

  @include tablet {
    max-width: 100%;
  }
}
