.useful {
  &__container {
    padding-top: 35px;
    padding-bottom: 100px;

    @include small-tablet {
      padding-bottom: 50px;
    }
  }

  &__head {
    position: relative;

    @include mobile {
      display: flex;
    }
  }

  &__heading {
    margin-bottom: 63px;
    @include h2;
    @include ff-MontserratAlter;
    text-align: center;

    @include small-tablet {
      margin-bottom: 50px;
    }

    @include mobile {
      text-align: left;
    }
  }

  &__buttons {
    position: absolute;
    right: 0;
    top: 60%;
    transform: translateY(-50%);
    width: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      width: 80px;
    }
  }
}

.swiper-button-next, .swiper-button-prev {
  color: var(--gray-80) !important;
}

.useful-next, .useful-prev {
  transition: color .3s ease-in-out;

  &:hover {
    color: var(--gray) !important;
  }
}

.useful-card {
  background-color: var(--white);
  box-shadow: inset 0 0 54px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  cursor: pointer;

  &__top {
    position: relative;
  }

  &__heading {
    padding: 19px 0 24px;
    border-top: 1px solid #EFF1E4;
    text-align: center;
  }

  &__heading a {
    @include fz-18;
    font-weight: 500;
    color: var(--gray-80);
    transition: color .3s ease-in-out;

    &:hover {
      color: var(--gray);
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    padding: 43px 24px 34px 51px;
    @include fz-16;
    font-weight: 400;
    color: var(--gray-80);
    background-color: var(--light-green);
    transition: opacity .3s ease-in-out;
    z-index: -1;

    @include tablet {
      padding: 43px 25px;
    }
  }

  &__description {
    margin-bottom: 42px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    @include ff-MontserratAlter;
    font-size: 28px;
    line-height: 42px;
    font-weight: 400;
    color: var(--gray);
  }

  &__link {
    @include btn-green;
    display: block;
  }
}

.useful-card .image {
  padding: 20px 0 10px;
  display: block;
  margin: 0 auto;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: 0;
    z-index: -1;
  }
}

.useful-card:hover .useful-card__content {
  opacity: 1;
  z-index: 2;
}

.fw-800 {
  font-weight: 800;
}


.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after,
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  @include small-tablet {
    font-size: 30px !important;
  }
}
