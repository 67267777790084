.advantages {
  background-color: var(--light-gray);

  &__container {
    padding-top: 75px;
    padding-bottom: 90px;

    @include small-tablet {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &__heading {
    @include ff-MontserratAlter;
    @include h2;
    text-align: center;
    margin-bottom: 64px;

    @include small-tablet {
      margin-bottom: 50px;
    }
  }
}

.advantages-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 45px;

  @include extra-desktop {
    gap: 30px 30px;
  }

  @include small-tablet {
    flex-direction: column;
  }

  &__item {
    flex-basis: 48%;
    display: flex;
    align-items: center;
    gap: 40px;

    @include tablet {
      gap: 30px;
    }
  }

  &__heading {
    margin-bottom: 5px;
    @include ff-MontserratAlter;
    font-size: 26px;
    line-height: 32px;
    font-weight: 900;
    color: var(--gray);

    @include tablet {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__text {
    @include fz-16;
    font-weight: 400;
    color: var(--gray);
  }
}
