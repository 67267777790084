.footer {
  background-color: var(--dark-gray);

  &__container {
    padding-top: 80px;
    padding-bottom: 70px;
    display: flex;
    gap: 60px;

    @include extra-desktop {
      padding-top: 50px;
    }

    @include small-tablet {
      flex-wrap: wrap;
      gap: 30px 0;
    }

    @include mobile {
      gap: 30px 5px;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;

    @include small-tablet {
      flex-basis: 50%;
    }

    @include mobile {
      flex-basis: 100%;
    }

  }

  &__logo {
    margin-bottom: 13px;
  }

  &__description {
    @include fz-16;
    font-weight: 400;
    color: var(--white);
    margin-bottom: 26px;
  }

  &__links {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include small-tablet {
      flex-basis: 50%;
      padding-top: 0;
    }
  }

  &__copyright {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #dcdcdc;
  }

  &__tel {
    white-space: nowrap;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: var(--green);
    transition: color .3s ease-in-out;

    &:hover {
      color: var(--green-hover);
    }
  }

  &__callback {
    margin-bottom: 20px;
    @include fz-16;
    font-weight: 400;
    color: var(--green);
    border-bottom: 1px solid var(--green);
    transition: border-color .3s ease-in-out, color .3s ease-in-out;

    &:hover {
      border-color: transparent;
      color: var(--green-hover)
    }
  }

  &__email {
    @include ff-Monserrat;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: var(--white);
  }

  &__offer, &__policy {
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    transition: border-color .3s ease-in-out;

    &:hover {
      border-color: transparent;
    }
  }

  &__policy {
    margin-bottom: 14px;

    @include small-tablet {
      display: block;
    }
  }

  &__malevich {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: #dcdcdc;
  }

  &__malevich-link {
    color: #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    transition: border-color .3s ease-in-out;

    &:hover {
      color: #dcdcdc;
      border-color: transparent;
    }
  }

  &__info {
    @include mobile {
      flex-basis: 50%;
    }
  }
}

.footer-nav {
  padding-top: 30px;

  @include small-tablet {
    flex-basis: 50%;
  }

  @include mobile {
    padding-top: 0;
    flex-basis: 100%;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;

    @include small-tablet {
      flex-direction: column;
    }
  }

  &__item:nth-child(odd) {
    flex-basis: 41%;
  }

  &__link {
    @include ff-Monserrat;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: var(--white);
    transition: color .3s ease-in-out;

    @include extra-desktop {
      font-size: 16px;
      line-height: 20px;
    }

    &:hover {
      color: var(--green);
    }
  }
}

.footer-social {
  display: flex;
  gap: 13px;
  margin-bottom: 14px;

  &__link {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-image: linear-gradient(144.34deg, #C9D824 14.99%, #86C232 30.49%, #00A99D 84.21%);
    background-size: 200% auto;
    transition: background-position .3s ease-in-out;

    &:hover {
      background-position: right bottom;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
    fill: var(--white);
  }
}
