.tabs {
  background-color: var(--light-gray);

  &__container {
    padding-top: 75px;
    padding-bottom: 90px;
  }

  &__heading {
    @include ff-MontserratAlter;
    @include h2;
    font-weight: 900;
    color: var(--gray);
    margin-bottom: 69px;
    text-align: center;
  }
}

.tabs-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;

  @include tablet {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  &__button {
    padding: 14px 19px;
    @include fz-18;
    font-weight: 500;
    color: var(--white);
    background-color: var(--dark-gray);
    border-radius: 8px;
    box-shadow: 0 10px 15px rgba(224, 225, 224, 0.2);
    transition: background-color .3s ease-in-out;

    &:hover {
      background-color: var(--green-86);
      color: var(--white);
    }
  }
}

.btn-active {
  background-color: transparent;
  color: var(--gray);
}

.tabs-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 273px;
  border-radius: 40px;
  overflow: hidden;

  @include tablet {
    grid-template-columns:  repeat(2, 1fr);
  }

  @include small-tablet {
    grid-template-columns: repeat(1, 1fr);
  }
}
