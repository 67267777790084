.fancybox__slide  .callback {
  background-color: var(--green);
  max-width: fit-content;
  width: 100%;

  &__content {
    display: flex;
    gap: 30px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    max-width: 40%;
    width: 100%;
  }

  &__description {
    @include fz-18;
    font-weight: 400;
    color: var(--white);
    max-width: 350px;
  }

  &__heading {
    max-width: 300px;
    font-size: 37px;
    line-height: 42px;
    @include ff-MontserratAlter;
    font-weight: 900;
    color: var(--white);
    margin-bottom: 35px;
  }
}

.callback-form {
  display: flex;
  flex-direction: column;

  &__fieldset {
    border: none;
    display: flex;
    margin: 0;
    justify-content: space-between;
    padding: 25px 35px 50px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 10px 15px rgb(134 194 50 / 20%);
  }

  &__label {
    width: 45%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 12px;
    gap: 5px;
    font-weight: 400;
    color: var(--gray);
  }

  &__input {
    border-bottom: 1px solid var(--gray);
    padding: 5px 0;
    @include fz-18;
    color: var(--gray);

    &::placeholder {
      @include fz-18;
      font-weight: 400;
      color: var(--light-gray);
    }
  }

  &__bottom {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }

  &__policy {
    max-width: 250px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: var(--white);
    text-align: right;
  }

  &__policy-link {
    color: var(--white);
    border-bottom: 1px solid var(--white);
    transition: border-color .3s ease-in-out;

    &:hover {
      border-color: transparent;
      color: var(--white);
    }
  }

  &__button {
    @include btn-orange;
  }
}
