@mixin btn-green {
  padding: 14px 24px;
  @include fz-18;
  font-weight: 500;
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  border: 2px solid transparent;
  transition: box-shadow .3s ease-in-out, background-color .3s ease-in-out, border-colo .3s ease-in-out;
  cursor: pointer;

  &:hover{
    box-shadow: 0 10px 15px rgba(134, 194, 50, 0.2);
    color: var(--white);
    background-color: var(--green-hover);
  }

  &:focus {
    outline: none;
    border-color: var(--green-hover);
  }
}
