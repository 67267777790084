// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Muller", "Montserrat", "MontserratAlter", sans-serif;
  --content-width: 1200px;
  --container-offset: 15px;
  --container-offset-1440: 60px;
  --container-offset-1024: 30px;
  --container-offset-320: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --white: #fff;
  --orange: #E95005;
  --orange-hover: #cf4805;
  --green: #6e9b29;
  --green-86: #86C232;
  --green-hover: #577a20;
  --dark-gray: #5E6763;
  --gray-80: #80937F;
  --gray: #6C7772;
  --light-gray: #EAEDEB;
  --light-green: #F2FFEC;
}
