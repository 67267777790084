.what-is {
  &__container {
    padding-top: 75px;
    padding-bottom: 90px;

    @include small-tablet {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}

.what-is__container h2 {
  @include h2;
  @include ff-MontserratAlter;
  font-weight: 900;
  color: var(--gray);
  padding: 0;
  margin: 0 0 72px;
  text-align: center;

  @include small-tablet {
    margin-bottom: 40px;
  }
}

.what-is__description p {
  margin: 0 0 1em;
  padding: 0;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  color: var(--gray);
  text-align: justify;

  @include small-tablet {
    font-size: 16px;
    line-height: 21px;
  }
}
